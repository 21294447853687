

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/swiper-bundle.css";
import "../css/LaserHairRemovalImageSwiper.css";

import HyperPulse from '../images/hyperPulse.png';
import HyperPulse02 from '../images/hyperPulse02.jpg';
import HyperPulse03 from '../images/home01.jpg';
import LaserHairRemoval01 from '../images/laserHairRemoval01.jpg';
import LaserHairRemoval02 from '../images/laserHairRemoval02.jpg';
import LaserHairRemoval03 from '../images/laserHairRemoval03.jpg';










SwiperCore.use([Navigation, Pagination, Autoplay]);

const LaserHairRemovalImageSwiper = () => {
    const customPrevButton = (
        <IoIosArrowBack size={30} color="#333" className="swiper-button-prev" />
    );

    const customNextButton = (
        <IoIosArrowForward size={30} color="#333" className="swiper-button-next" />
    );

    return (
        <Swiper
            navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            }}
            pagination={{ clickable: true }}
            loop
            speed={1000}
            autoplay={{ delay: 1000 }} // Set autoplay delay to 1 second
            className="swiper4 p-8 my-6"
        >

            <SwiperSlide>
                <img
                    src={HyperPulse03}
                    alt=""
                />
            </SwiperSlide>
            <SwiperSlide>
                <img
                    src={LaserHairRemoval01}
                    alt=""
                />
            </SwiperSlide>
            <SwiperSlide>
                <img
                    src={LaserHairRemoval02}
                    alt=""
                />
            </SwiperSlide>
            <SwiperSlide>
                <img
                    src={LaserHairRemoval03}
                    alt=""
                />
            </SwiperSlide>



            <div className="swiper-button-prev-custom">{customPrevButton}</div>
            <div className="swiper-button-next-custom">{customNextButton}</div>
        </Swiper>


    );
};

export default LaserHairRemovalImageSwiper;
