import React from 'react'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "../css/ElectrolysisTextSwiper.css";
// Add the missing import statements
// Import Swiper components and styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Import the base Swiper styles
import "swiper/css/navigation"; // Import Swiper navigation styles
import "swiper/css/pagination"; // Import Swiper pagination styles
import "swiper/css/autoplay"; // Import Swiper autoplay styles

// Import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";

SwiperCore.use([Navigation, Pagination, Autoplay]);
const ElectrolysisTextSwiper = () => {
    const customPrevButton = (
        <IoIosArrowBack size={30} color="#4f5186" className="swiper-button-prev" />
    );

    const customNextButton = (
        <IoIosArrowForward size={30} color="#4f5186" className="swiper-button-next" />
    );

    return (
        <Swiper
            navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            }}
            pagination={{ clickable: true }}
            loop
            speed={1000}
            autoplay={{ delay: 1000 }} // Set autoplay delay to 1 second
            className="swiper2 p-8 my-4"
        >
  

            <SwiperSlide>
                {/* <img src="https://images.pexels.com/photos/3785417/pexels-photo-3785417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="client_photo" style={{ width: '80px', height: '80px' }} className='rounded-full mx-auto' /> */}
                <p className='px-12 mt-6 text-xs lg:text-lg mx-auto font-medium max-w-[90%]  text-mainBtn' >
                    "My daughter started doing to Devora for electrolysis 2 years ago and now she went after 6 months for a little clean up Bh she's so happy with the results, highly recommended, she's very gentle and amazing at what she does"
                </p>
                {/* <p className='text-sm mx-auto rtl font-bold text-secondary mt-2' dir='rtl'>אורית מימון
                </p> */}


            </SwiperSlide>
            <SwiperSlide>
                {/* <img src="https://images.pexels.com/photos/3785417/pexels-photo-3785417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="client_photo" style={{ width: '80px', height: '80px' }} className='rounded-full mx-auto' /> */}
                <p className='px-12 mt-6 text-xs lg:text-lg mx-auto font-medium max-w-[90%]  text-mainBtn' >
                    "Devorah is very professional and I'd recommend her to anyone! I've had great results so far, facial hairs are getting lighter and thinner and I don't need many top-ups. I also enjoy her company! הצלחה and thanks!"
                </p>
                {/* <p className='text-sm mx-auto rtl font-bold text-secondary mt-2' dir='rtl'>אורית מימון
                </p> */}


            </SwiperSlide>
            <SwiperSlide>
                {/* <img src="https://images.pexels.com/photos/3785417/pexels-photo-3785417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="client_photo" style={{ width: '80px', height: '80px' }} className='rounded-full mx-auto' /> */}
                <p className='px-12 mt-6 text-xs lg:text-lg mx-auto font-medium max-w-[90%]  text-mainBtn' >
                    "I didn't think I'd ever get rid of my moustache but ב"ה. She did it !! and a pregnancy and birth and it is still basically non-existent!! Brill service!! Thanks Devorah!"
                </p>
                {/* <p className='text-sm mx-auto rtl font-bold text-secondary mt-2' dir='rtl'>אורית מימון
                </p> */}


            </SwiperSlide>
            <SwiperSlide>
                {/* <img src="https://images.pexels.com/photos/3785417/pexels-photo-3785417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="client_photo" style={{ width: '80px', height: '80px' }} className='rounded-full mx-auto' /> */}
                <p className='px-12 mt-6 text-xs lg:text-lg mx-auto font-medium max-w-[90%]  text-mainBtn' >
                    "I came here with eyebrows that needed urgent attention and an upper lip and the results are just amazing. I look in the mirror and am just so grateful -Thanx!!"
                </p>
                {/* <p className='text-sm mx-auto rtl font-bold text-secondary mt-2' dir='rtl'>אורית מימון
                </p> */}


            </SwiperSlide>
            <SwiperSlide>
                {/* <img src="https://images.pexels.com/photos/3785417/pexels-photo-3785417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="client_photo" style={{ width: '80px', height: '80px' }} className='rounded-full mx-auto' /> */}
                <p className='px-12 mt-6 text-xs lg:text-lg mx-auto font-medium max-w-[90%]  text-mainBtn' >
                    "I started electrolysis on my eyebrows 10 years ago - did about 2 years of sessions and have never touched them since! Thanx"
                </p>
                {/* <p className='text-sm mx-auto rtl font-bold text-secondary mt-2' dir='rtl'>אורית מימון
                </p> */}
            </SwiperSlide>


            <div className="swiper-button-prev-custom">{customPrevButton}</div>
            <div className="swiper-button-next-custom">{customNextButton}</div>
        </Swiper>
    );
}

export default ElectrolysisTextSwiper