import React, { useEffect, useRef } from 'react'; // Import the 'useRef' hook
import HomeBanner from '../images/home-banner.jpg';
import Home01 from '../images/home01.jpg';
import ScrollToTopOnRefresh from '../components/ScrollToTopOnRefresh'; // Corrected import
import AOS from 'aos';
import 'aos/dist/aos.css';
import FadeUp from '../components/FadeUp';
import HomeImageSwiper from '../components/HomeImageSwiper';
import TypewriterComponent from '../components/TypewriterComponent'
import HomeFixed from '../images/homeFixed.jpg';
import "../css/FixedBg.css";
import ImageScroll from '../components/ImageScroll';


function Home() {

  useEffect(() => {
    AOS.init({
      // Customize AOS options as needed
      duration: 1000, // Duration of animations in milliseconds
      once: true, // Whether animations should only happen once
      // Add more options here...
      delay: 200
    });
  }, []);


  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollY = window.scrollY;
        containerRef.current.style.backgroundPositionY = -scrollY * 2 + 'px';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    <div className='p-6 imgBg'>
      <ScrollToTopOnRefresh />
      <FadeUp>


        <h1 className='text-center text-3xl sm:text-5xl my-4 font-semibold text-secondaryBtn'>WHY <br /> DEVORAH'S LANE?</h1>
        <div className='bg-[#ed9aa7] h-[2px] w-[300px] sm:w-[500px] mx-auto'></div>

        <div className='text-left text-secondaryBtn my-6 sm:text-2xl mx-auto max-w-[1200px] font-medium'>

          <p>
            As a woman and mother of young adults, I understand we want to look and feel good about ourselves, no matter what age and stage we are at. I understand your desires, your dreams, what bothers and worries you. I care for my clients and do everything to get the best results.
          </p>
          {/* <img className='my-6 mx-auto rounded-xl' src={HomeBanner} alt="" /> */}

          <HomeImageSwiper />

          <p className='mt-4'>
            Through beauty treatments in my salon, you can take the time to recharge, focus on and improve yourself in order to be the better person you want to be. We are often so busy looking after everyone else that we don’t get the personal time we need.
          </p>

          <p className='mt-4'>
            The beauty and aesthetics industry is extremely competitive, every business claims themselves as the best. I welcome you to come in and meet me, I offer free consultations and would be happy to work through a treatment plan.
          </p>

          <p className='mt-4'>
            I am totally committed to give you a professional treatment at the same time, not compromising your safety. I keep strict levels of sterilization, offering a clean hygienic clinic. I make sure to be updated with the developments in the industry.
          </p>

          <p className='mt-4 font-bold'>
            My salon has a full range of treatments so that you don't have to shop around.
          </p>
        </div>

        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>

        <img src={HomeFixed} className='fixed' alt="" />

        {/* <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn'>ABOUT DEVORAH</h1>

        <div className='text-center text-secondaryBtn flex flex-col my-6 sm:text-2xl mx-auto max-w-[1200px] font-medium'>
          <p className='mr-4'>
            <TypewriterComponent text=" A passionate cosmetician who puts my customers first,
         I’ve been working in the field for over 30 years in South Africa, the UK, and Israel.
          Since qualifying in Johannesburg in 1989,
           I’ve ensured to keep my knowledge continually up to date though online and face-to-face training,
            to maintain pace with the latest available treatments and technologies for all ages and skin types."
            />
          </p>
        </div> */}


    

        <div className="text-center text-secondaryBtn flex flex-col my-6 sm:text-2xl mx-auto max-w-[1200px] font-medium">
          <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn'>ABOUT DEVORAH</h1>

          <div className='text-center text-secondaryBtn flex flex-col my-6 sm:text-2xl mx-auto max-w-[1200px] font-medium'>
            <p className='mr-4'>

            </p>

            <p>" A passionate cosmetician who puts my customers first,
              I’ve been working in the field for over 30 years in South Africa, the UK, and Israel.
              Since qualifying in Johannesburg in 1989,
              I’ve ensured to keep my knowledge continually up to date though online and face-to-face training,
              to maintain pace with the latest available treatments and technologies for all ages and skin types."</p>
          </div>
        </div>
      </FadeUp>
    </div>
  );

}

export default Home;
