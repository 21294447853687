import React from 'react';
import Logoimg from '../images/logo.png';
import { AiOutlineHeart, AiOutlineWhatsApp, AiOutlineInstagram, AiOutlineCar } from 'react-icons/ai';
import { GrFacebookOption } from 'react-icons/gr';
import { RiPhoneFill } from 'react-icons/ri';
function Footer() {


    return (
        <footer className="pb-6 imgBg">
            <div className='bg-[#ed9aa7] h-[2px] w-full mx-auto'></div>

            <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn'>GET IN TOUCH</h1>
            <h1 className='text-center text-2xl sm:text-4xl my-4 font-semibold text-[#eb9ba3] oleo '>I'd love to hear from you</h1>

            <h1 className='text-center text-3xl sm:text-5xl font-bold text-secondaryBtn'>0584060644</h1>




            <div className='max-w-[350px] flex justify-around mx-auto'>
                <a href="tel:+972584060644" target="_blank" className='flex flex-col justify-center items-center w-[100px] h-[100px] '>
                    <div className="group flex items-center justify-center w-11 h-11 rounded-full border-[1px] border-ring bg-gradient-to-l from-mainRing to-secondaryRing hover:scale-[110%] duration-200">
                        <div className="group-hover:scale-[110%]">
                            <RiPhoneFill size={25} className='text-icons' />
                        </div>
                    </div>
                </a>
                <a href="https://wa.me/972584060644" target="_blank" className='flex flex-col justify-center items-center w-[100px] h-[100px]'>
                    <div className="group flex items-center justify-center w-11 h-11 rounded-full border-[1px] border-ring bg-gradient-to-l from-mainRing to-secondaryRing hover:scale-[110%] duration-200">
                        <div className="group-hover:scale-[110%]">
                            <AiOutlineWhatsApp size={25} className='text-icons' />
                        </div>
                    </div>
                </a>
                <a href='https://www.instagram.com/devorahslane' target="_blank" className='flex flex-col justify-center items-center w-[100px] h-[100px]'>
                    <div className="group flex items-center justify-center w-11 h-11 rounded-full border-[1px] border-ring bg-gradient-to-l from-mainRing to-secondaryRing hover:scale-[110%] duration-200">
                        <div className="group-hover:scale-[110%]">
                            <AiOutlineInstagram size={25} className='text-icons' />
                        </div>
                    </div>
                </a>


                <a href='https://www.facebook.com/devorahyaffebarr?mibextid=LQQJ4d' target="_blank" className='flex flex-col justify-center items-center w-[100px] h-[100px]'>
                    <div className="group flex items-center justify-center w-11 h-11 rounded-full border-[1px] border-ring bg-gradient-to-l from-mainRing to-secondaryRing hover:scale-[110%] duration-200">
                        <div className="group-hover:scale-[110%]">
                            <GrFacebookOption size={25} className='text-icons' />
                        </div>
                    </div>
                </a>

            </div>

            <div className='flex flex-col lg:flex-row justify-around mx-auto lg:max-w-[600px] max-w-[200px] text-xs'>
 
                <a href="https://devorahslane.netlify.app/" target="_blank" className='flex flex-col justify-center items-center bg-gradient-to-tr from-mainBtn to-secondaryBtn border-textSave text-textSave border-2 py-2 px-4 my-2 rounded-full font-semibold hover:scale-125 duration-300'>
                    <h1>Digicard</h1>
                </a>
                <a href="https://devorahslanehebrew.netlify.app/" target="_blank" className='flex flex-col justify-center items-center bg-gradient-to-tr from-mainBtn to-secondaryBtn border-textSave text-textSave border-2 py-2 px-4 my-2  rounded-full font-semibold hover:scale-125 duration-300'>
                    <h1>Digicard (Hebrew) </h1>
                </a>
                <a href="https://devorahspricelist.netlify.app/" target="_blank" className='flex flex-col justify-center items-center bg-gradient-to-tr from-mainBtn to-secondaryBtn border-textSave text-textSave border-2 py-2 px-4 my-2  rounded-full font-semibold hover:scale-125 duration-300'>
                    <h1>Price List</h1>
                </a>
                <a href="https://devorahspricelisthebrew.netlify.app/" target="_blank" className='flex flex-col justify-center items-center bg-gradient-to-tr from-mainBtn to-secondaryBtn border-textSave text-textSave border-2 py-2 px-4 my-2  rounded-full font-semibold hover:scale-125 duration-300'>
                    <h1>Price List (Hebrew)</h1>
                </a>
            </div>
        </footer>
    );
}

export default Footer;
