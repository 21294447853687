import React from 'react';
import Electrolysis01 from '../images/electrolysis01.png';
import Electrolysis02 from '../images/electrolysis02.png';
import ScrollToTopOnRefresh from '../components/ScrollToTopOnRefresh'; // Corrected import
import FadeUp from '../components/FadeUp';
import ElectrolysisTextSwiper from '../components/ElectrolysisTextSwiper'


function Electrolysis() {


  return (
    <div className='p-6 imgBg'>
      <ScrollToTopOnRefresh />
      <FadeUp>
        <h1 className='text-center text-3xl sm:text-5xl my-4 font-semibold text-secondaryBtn'>ELECTROLYSIS</h1>
        <div className='bg-[#ed9aa7] h-[2px] w-[300px] sm:w-[500px] mx-auto'></div>

        <div className='text-left text-secondaryBtn my-6 sm:text-2xl mx-auto max-w-[1200px] font-medium'>
          <p>
            Two commonly used methods of permanent hair removal are electrolysis and laser (I.P.L).
            for more information on laser
            Both target hair follicles under the surface of the skin to slow down – and in the case of
            electrolysis – prevent regrowth. Electrolysis is the only permanent hair removal method endorsed by the FDA and is suitable on all skin and hair types.
            People spend so much time removing unwanted hair only to find even more the next day. Or they go out thinking they’ve got them all, touch their face and find there’s one they’ve missed. I've been in this business for many years and see the way it effects girls and woman alike. Severe cases of skin infection caused by ingrown hairs are another common problem. I love the results electrolysis provides and have seen first-hand how life-changing it is.
          </p>
          <p className='mt-2'>
            There are many electrolysis machines on the market, many of which I’ve worked with. The technology is constantly improving and machines are becoming quicker and less painful. The machine I currently use, APILUS platinum pure, delivers currents at high temperatures with a speed of 1000th of a second, avoiding skin damage and assuring maximum comfort. It offers the three modalities – electrolysis, thermolysis and blend – making it suitable for any hair or skin type and pain tolerance. I use different probes according to your skin’s sensitivity, and to ensure your health and safety, they are all single-use and of the highest quality.
          </p>
        </div>

        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>

        <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn'>CLIENT STORIES</h1>
        <ElectrolysisTextSwiper />

        {/* <div className='text-left text-secondaryBtn my-6 sm:text-2xl mx-auto max-w-[600px] font-medium'>
          <p className='border-2 p-4 border-[#ed9aa7] rounded-xl text-xs sm:text-base'>
            "My daughter started doing to Devora for electrolysis 2 years ago and now she went after 6 months for a little clean up Bh she's so happy with the results, highly recommended, she's very gentle and amazing at what she does"
          </p>
          <p className='mt-4 border-2 p-4 border-[#ed9aa7] rounded-xl text-xs sm:text-base'>
            "Devorah is very professional and I'd recommend her to anyone! I've had great results so far, facial hairs are getting lighter and thinner and I don't need many top-ups. I also enjoy her company! הצלחה and thanks!"
          </p>
          <p className='mt-4 border-2 p-4 border-[#ed9aa7] rounded-xl text-xs sm:text-base'>
            "I didn't think I'd ever get rid of my moustache but ב"ה. She did it !! and a pregnancy and birth and it is still basically non-existent!! Brill service!! Thanks Devorah! x"
          </p>
          <p className='mt-4 border-2 p-4 border-[#ed9aa7] rounded-xl text-xs sm:text-base'>
            "I came here with eyebrows that needed urgent attention and an upper lip and the results are just amazing. I look in the mirror and am just so grateful -Thanx!!"
          </p>
          <p className='mt-4 border-2 p-4 border-[#ed9aa7] rounded-xl text-xs sm:text-base'>
            "I started electrolysis on my eyebrows 10 years ago - did about 2 years of sessions and have never touched them since! Thanx"
          </p>
        </div> */}
        <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn'> BEFORE & AFTER</h1>

        <div className='mt-10 flex flex-col sm:flex-row justify-around mx-auto max-w-[1200px] '>
          <img className='w-[90%] sm:w-[40%] mx-auto rounded-xl ' src={Electrolysis01} alt="" />
          <img className='w-[90%] sm:w-[40%] mx-auto rounded-xl mt-10 sm:mt-0' src={Electrolysis02} alt="" />
        </div>
      </FadeUp>
    </div>
  );


}

export default Electrolysis;
