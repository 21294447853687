import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logoimg from '../images/logo.png';

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation(); // Get the current location

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <div className='flex flex-col bg-white  items-center py-5 imgBg'>
            <nav className='flex w-full text-secondaryBtn justify-around  font-medium smplusvisible shadow-xl'>
                <div className='flex flex-col items-center'>
                    <Link to={'/'}>
                        <img src={Logoimg} className='w-[200px] my-4' alt="logo" />

                    </Link>

                    <h1 className='text-center  text-xs font-medium text-secondaryBtn mb-5'>HAIR REMOVAL & SKIN CARE CLINIC</h1>
                </div>
                <Link className={`px-4 py-2 my-auto  border-2 border-mainBtn rounded-full hover:scale-125 duration-500 ${location.pathname === '/' ? 'font-bold  bg-[#ed9aa7] text-white rounded-full border-none' : ''}`} to="/" onClick={closeMenu}>HOME</Link>
                <Link className={`px-4 py-2 my-auto  border-2 border-mainBtn rounded-full hover:scale-125 duration-500 ${location.pathname === '/about' ? 'font-bold  bg-[#ed9aa7] text-white rounded-full border-none' : ''}`} to="/about" onClick={closeMenu}>ABOUT</Link>
                <Link className={`px-4 py-2 my-auto border-2 border-mainBtn rounded-full hover:scale-125 duration-500 ${location.pathname === '/electrolysis' ? 'font-bold  bg-[#ed9aa7] text-white rounded-full border-none' : ''}`} to="/electrolysis" onClick={closeMenu}>ELECTROLYSIS</Link>
                <Link className={`px-4 py-2 my-auto border-2 border-mainBtn rounded-full hover:scale-125 duration-500 ${location.pathname === '/laser-hair-removal' ? 'font-bold  bg-[#ed9aa7] text-white rounded-full border-none' : ''}`} to="/laser-hair-removal" onClick={closeMenu}>LASER HAIR REMOVAL</Link>
                <Link className={`px-4 py-2 my-auto  border-2 border-mainBtn rounded-full hover:scale-125 duration-500 ${location.pathname === '/laser-skin-care' ? 'font-bold  bg-[#ed9aa7] text-white rounded-full border-none' : ''}`} to="/laser-skin-care" onClick={closeMenu}>LASER SKIN CARE</Link>
                <Link className={`px-4 py-2 my-auto  border-2 border-mainBtn rounded-full hover:scale-125 duration-500 ${location.pathname === '/skin-care' ? 'font-bold  bg-[#ed9aa7] text-white rounded-full border-none' : ''}`} to="/skin-care" onClick={closeMenu}>SKIN CARE</Link>


            </nav>

            <div className='flex flex-col items-center lg:hidden'>
                <Link to={'/'}>
                    <img src={Logoimg} className='w-[300px] my-4' alt="logo" />

                </Link>

                <h1 className='text-center  text-sm font-medium text-secondaryBtn mb-5'>HAIR REMOVAL & SKIN CARE CLINIC</h1>
            </div>
            {/* Burger Icon */}
            <div className='lg:hidden top-0'>
                <button className=' mb-4 text-xl w-12 h-12 bg-[#ed9aa7] text-white rounded-full' onClick={toggleMenu}>
                    {menuOpen ? 'X' : '☰'}
                </button>
            </div>


            <nav className={`flex  h-screen flex-col text-secondaryBtn text-center  font-medium ${menuOpen ? 'block' : 'hidden'}`}>
                <Link className={`px-4 my-2 border-2 border-mainBtn rounded-full text-xl hover:scale-125 duration-500 ${location.pathname === '/' ? 'font-bold   text-white rounded-full bg-[#ed9aa7] border-none' : ''}`} to="/" onClick={closeMenu}>HOME</Link>
                <Link className={`px-4 my-2 border-2 border-mainBtn rounded-full text-xl hover:scale-125 duration-500 ${location.pathname === '/about' ? 'font-bold  bg-[#ed9aa7] text-white rounded-full border-none' : ''}`} to="/about" onClick={closeMenu}>ABOUT</Link>
                <Link className={`px-4 my-2 border-2 border-mainBtn rounded-full text-xl hover:scale-125 duration-500 ${location.pathname === '/electrolysis' ? 'font-bold  bg-[#ed9aa7] text-white rounded-full border-none' : ''}`} to="/electrolysis" onClick={closeMenu}>ELECTROLYSIS</Link>
                <Link className={`px-4 my-2 border-2 border-mainBtn rounded-full text-xl hover:scale-125 duration-500 ${location.pathname === '/laser-hair-removal' ? 'font-bold  bg-[#ed9aa7] text-white rounded-full border-none' : ''}`} to="/laser-hair-removal" onClick={closeMenu}>LASER HAIR REMOVAL</Link>
                <Link className={`px-4 my-2 border-2 border-mainBtn rounded-full text-xl hover:scale-125 duration-500 ${location.pathname === '/laser-skin-care' ? 'font-bold  bg-[#ed9aa7] text-white rounded-full border-none' : ''}`} to="/laser-skin-care" onClick={closeMenu}>LASER SKIN CARE</Link>
                <Link className={`px-4 my-2 border-2 border-mainBtn rounded-full text-xl hover:scale-125 duration-500 ${location.pathname === '/skin-care' ? 'font-bold  bg-[#ed9aa7] text-white rounded-full border-none' : ''}`} to="/skin-care" onClick={closeMenu}>SKIN CARE</Link>


            </nav>



        </div>
    );
}

export default Navbar;
