import React from 'react';
import LaserSkinCare01 from '../images/laserSkinCare01.png';
import LaserSkinCare02 from '../images/laserSkinCare02.png';
import LaserSkinCare03 from '../images/laserSkinCare03.png';
import LaserSkinCare04 from '../images/laserSkinCare04.png';
import LaserSkinCare05 from '../images/laserSkinCare05.png';
import LaserSkinCare06 from '../images/laserSkinCare06.png';
import LaserSkinCare07 from '../images/laserSkinCare07.png';
import LaserSkinCare08 from '../images/laserSkinCare08.png';
import ScrollToTopOnRefresh from '../components/ScrollToTopOnRefresh'; // Corrected import
import FadeUp from '../components/FadeUp';
import ImageScroll from '../components/ImageScroll';
import HomeFixed from '../images/homeFixed.jpg';


function LaserSkinCare() {

  return (
    <div className='p-6 imgBg'>
      <ScrollToTopOnRefresh />
      <FadeUp>
        <h1 className='text-center text-3xl sm:text-5xl my-4 font-semibold text-secondaryBtn'>LASER SKIN CARE</h1>
        <div className='bg-[#ed9aa7] h-[2px] w-[70%] sm:w-[500px] mx-auto'></div>

        {/* <div className='text-left text-secondaryBtn my-6 sm:text-2xl mx-auto max-w-[1200px] font-medium'>
          <p className='mt-2'>
            Skin IPL Laser treatments (also called photo-rejuvenation or photo-facial), is a technology that uses quick and powerful flashes of light to reverse the <span className='font-bold'>skin ageing process.</span>
          </p>
          <img className='my-6 mx-auto rounded-xl' src={LaserSkinCare01} alt="" />

          <p className='mt-2'>
            With this treatment, light energy penetrates beneath the skin's surface. Fibroblast cells then create new collagen and elastin fibers (which support and keep the skin firm), leaving you with a healthy, fresh look.
          </p>

          <img className='my-6 mx-auto rounded-xl' src={LaserSkinCare02} alt="" />

          <p className='mt-2 font-bold'>
            IPL can also treat:
          </p>
          <li className="list-item">
            Pigmentation (sun and age spots).
          </li>
          <li className='mt-2 list-item'>
            Broken capillaries.
          </li>
          <li className='mt-2 list-item'>
            Acne and acne scarring.
          </li>
          <li className='mt-2 list-item'>
            Minimize the appearance of enlarged pores.
          </li>
        </div> */}



        <div>
          <div className='text-left text-secondaryBtn my-6 sm:text-2xl mx-auto max-w-[1200px] font-medium'>
            <p className='mt-2'>
              Skin IPL Laser treatments (also called photo-rejuvenation or photo-facial), is a technology that uses quick and powerful flashes of light to reverse the <span className='font-bold'>skin ageing process.</span>
            </p>
            <img className='my-6 mx-auto rounded-xl' src={LaserSkinCare01} alt="" />

            <p className='mt-2'>
              With this treatment, light energy penetrates beneath the skin's surface. Fibroblast cells then create new collagen and elastin fibers (which support and keep the skin firm), leaving you with a healthy, fresh look.
            </p>

            <img className='my-6 mx-auto rounded-xl' src={LaserSkinCare02} alt="" />

            {/* <p className='mt-2 font-bold'>
                IPL can also treat:
              </p>
              <li className="list-item">
                Pigmentation (sun and age spots).
              </li>
              <li className='mt-2 list-item'>
                Broken capillaries.
              </li>
              <li className='mt-2 list-item'>
                Acne and acne scarring.
              </li>
              <li className='mt-2 list-item'>
                Minimize the appearance of enlarged pores.
              </li> */}
          </div>

        </div>

        <div className='mx-auto max-w-[1200px]'>
          <div className='text-center text-white mx-auto my-6   max-w-md sm:text-2xl  font-medium'>
            <p className='rounded-full font-bold text-secondaryBtn  my-4 mx-auto '>
              IPL can also treat:
            </p>
            <p className='bg-[#ed9aa7] rounded-full  py-4 mx-auto '>
              Pigmentation (sun and age spots).
            </p>
            <p className='mt-4 bg-[#ed9aa7] rounded-full p-4 mx-auto '>
              Broken capillaries.
            </p>
            <p className='mt-4 bg-[#ed9aa7] rounded-full  p-4 mx-auto '>
              Acne and acne scarring.
            </p>
            <p className='mt-4 bg-[#ed9aa7] rounded-full   p-4 mx-auto '>
              Minimize the appearance of enlarged pores.
            </p>
          </div>
        </div>

        {/* <div className='mx-auto max-w-[1200px]'>
          <div className='text-center text-white mx-auto my-6   max-w-md sm:text-2xl  font-medium'>
            <p className='bg-[#ed9aa7] rounded-full  py-4 mx-auto '>
              PAIN FREE
            </p>
            <p className='mt-4 bg-[#ed9aa7] rounded-full p-4 mx-auto '>
              FAST
            </p>
            <p className='mt-4 bg-[#ed9aa7] rounded-full  p-4 mx-auto '>
              SUITABLE ON TEENAGERS
            </p>
            <p className='mt-4 bg-[#ed9aa7] rounded-full   p-4 mx-auto '>
              LONG LASTING RESULTS
            </p>
          </div>
        </div> */}


        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>

        <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn'>CLIENTS</h1>

        <div className='my-10 flex flex-col sm:flex-row justify-around mx-auto max-w-[1200px] '>
          <img className='w-[90%] sm:w-[40%] mx-auto rounded-xl' src={LaserSkinCare03} alt="" />
          <img className='w-[90%] sm:w-[40%] mx-auto mt-10 sm:mt-0 rounded-xl' src={LaserSkinCare04} alt="" />
        </div>

        <div className='text-left text-secondaryBtn my-6 sm:text-2xl mx-auto max-w-[600px] font-medium'>
          <p className='border-2 p-4 border-[#ed9aa7] rounded-xl text-xs sm:text-base'>
            "Devorah is an amazing Cosmeticait, like a magician! after doing eclecrolyses at another Cosmeticait who burned my skin, I arrived at Devorah and she saved me! my skin was in a disastrous state on my face and she fixed everything. There is no more professional than her and she pays careful attention to all the details. She is very attentive, and he is a very nice person! I very recommend to visit her! 💕"
          </p>
        </div>

        <div className='my-10 flex flex-col sm:flex-row justify-around mx-auto max-w-[1200px] '>
          <img className='w-[90%] sm:w-[40%] mx-auto rounded-xl' src={LaserSkinCare05} alt="" />
          <img className='w-[90%] sm:w-[40%] mx-auto mt-10 sm:mt-0 rounded-xl' src={LaserSkinCare06} alt="" />
        </div>

        <div className='my-10 flex flex-col sm:flex-row justify-around mx-auto max-w-[1200px] '>
          <img className='w-[90%] sm:w-[40%] mx-auto rounded-xl' src={LaserSkinCare07} alt="" />
          <img className='w-[90%] sm:w-[40%] mx-auto mt-10 sm:mt-0 rounded-xl' src={LaserSkinCare08} alt="" />
        </div>
      </FadeUp>
    </div>
  );


}

export default LaserSkinCare;
