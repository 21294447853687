import React from 'react';
import { Link } from 'react-router-dom';
import SkinCare01 from '../images/SkinCare01.png';
import SkinCare02 from '../images/SkinCare02.png';
import ScrollToTopOnRefresh from '../components/ScrollToTopOnRefresh'; // Corrected import
import CustomAccordion from '../components/CustomAccordion';
import FadeUp from '../components/FadeUp';
import ImageScroll from '../components/ImageScroll';
import HomeFixed from '../images/homeFixed.jpg';

function SkinCare() {


  return (
    <div className='p-6 imgBg'>
      <ScrollToTopOnRefresh />
      <FadeUp>
        <h1 className='text-center text-3xl sm:text-5xl my-4 font-semibold text-secondaryBtn'>SKIN CARE</h1>
        <div className='bg-[#ed9aa7] h-[2px] w-[70%] sm:w-[400px] mx-auto'></div>



        <CustomAccordion title="Did you know?" text="The skin is the largest and most important organs the human body has. The skin forms a protective barrier between the external environment and the internal organs." />


        <img className='my-6  mx-auto rounded-xl' src={SkinCare01} alt="" />

        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>

        <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn max-w-[1200px] mx-auto'>SKIN KEEPS THE WORLD OUT <br /> AND THE BODY IN!</h1>
        <div className='text-left text-secondaryBtn  my-6  sm:text-2xl mx-auto  max-w-[1200px] font-medium'>


          <p className='mt-2'>

            As the most exposed, visible part of our body our facial skin acts as a crucial barrier against the external environment, guarding it from the elements, pollution, the sun and UV rays. But because of its thin, delicate structure, it's also susceptible to ageing.        </p>

          <p className='mt-2'>
            Our facial skin’s look & feel is a clear indicator of our health and wellbeing and its appearance, playing a significant role in our self-esteem.  In addition to significant discomfort, some skin conditions can sometimes be the source of significant embarrassment. Examples include acne, pigmentation and natural signs of ageing.        </p>


          <p className='mt-2'>
            All different skin types demand a different level of care. With so many products available on the internet, from pharmacy chains and other outlets, it’s crucial to ensure you select a product that is both reputable and a right fit for your individual needs.
          </p>

          <p className='mt-2'>
            This where I add value. With my 30 years’ experience in skin care, I can put you on track to ensure you have right treatments and products for your needs. After performing an in-depth skin analysis, I can help you embark on the right treatment that will leave you wearing your skin with confidence and comfort.        </p>







        </div>

        <div className='mx-auto max-w-[600px]'>      <img className='my-6  mx-auto rounded-xl w-full' src={SkinCare02} alt="" />
        </div>


        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>

        <div className='text-left text-secondaryBtn  my-6  sm:text-2xl mx-auto  max-w-[1200px] font-medium'>


          <p className='mt-2'>

            It’s important to take care of your skin by using good cosmetic products and having regular facials. Following my research of the many products on market and consulting with my cosmetologist peers in Israel, South Africa and the UK, I chose to offer services and treatments from Dermalogica and Leo Cosmetics. Results show that together with my treatments, these provide an excellent basis for your skin care routine.        </p>

          <p className='mt-2'>
            Having a facial is one of the best ways to take care of your skin. The recommendation for optimum skin health is to space your facials 4-6 weeks apart.
          </p>
          <p className='mt-2'>
            Multi-step skin treatment includes an analysis of your skin to determine its unique needs, followed by a double cleanse, exfoliation, steam, extractions (to unclog your pores and deep cleanse your skin), and concluding with a good massage to promote blood circulation and lymphatic drainage to deeply moisturize your skin. The treatment promotes a clear, well-hydrated complexion that can help your skin look clear, smooth and rejuvenated.
          </p>
          <p className='mt-2'>
            I also offer different facial types to improve the health and appearance of your skin – whether you’re looking to simply keep your skin supple, moisturized and young looking; clear black heads and other blemishes; or roll back the clock with electronic anti-ageing treatments.
          </p>
          <p className='mt-2'>
            Keeping a good home care routine, keeps your
            skin healthy in-between facials.
          </p>
        </div>
        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>



        <div className='text-left text-secondaryBtn  my-6  sm:text-2xl mx-auto  max-w-[1200px] font-medium'>


          <p className='mt-2'>
            Our skin ages every day. But taking preventative measures can help slow the process.

            My clinic is equipped with some of the latest electrical treatment technology, allowing me to support your skin health and even reverse the ageing process. The results are amazing, as is the feedback from my clients.
          </p>




        </div>

        <div className='text-center text-secondaryBtn  mb-12 mt-6 sm:text-2xl mx-auto max-w-[1200px] font-medium'>
          <p className='font-bold'>
            Click the button
            <br />
            To learn about IPL laser skin treatments
          </p>


          <Link to={'/laser-skin-care'} className="max-w-[220px] py-3 mt-4 text-base cursor-pointer mx-auto rounded-full flex border-4 bg-gradient-to-l from-mainBtn to-secondaryBtn text-textBtn px-7 border-borderBtn hover:opacity-[0.80]  duration-300 shadow-lg"
          >
            LASER SKIN CARE

          </Link>

        </div>

        <div className='text-left text-secondaryBtn  my-6  sm:text-2xl mx-auto  max-w-[1200px] font-medium'>


          <p className='mt-2'>
            My goal is to address issues associated with acne or ageing and help you maintain a youthful, healthy appearance - whether it's sun-related pigmentation (age spots), tired skin, fine lines or an uneven texture.
          </p>

          <p className='mt-2'>
            Indirect high frequency: Ideal for skin that needs stimulation, such as dry, delicate, sensitive, dull or loose skin. When combined with a gentle massage technique, the high frequency of this treatment stimulates your blood, lymph and sebaceous activity without pulling or stretching the skin, allowing it to become more relaxed.
          </p>

          <div className='mx-auto max-w-[1200px]'>
            <div className='text-center text-white mx-auto my-6   max-w-md sm:text-2xl  font-medium'>

              <p className='bg-[#ed9aa7] rounded-full  py-4 mx-auto '>
                regenerating.
              </p>
              <p className='mt-4 bg-[#ed9aa7] rounded-full p-4 mx-auto '>
                revitalizing.
              </p>
              <p className='mt-4 bg-[#ed9aa7] rounded-full  p-4 mx-auto '>
                healing.
              </p>

            </div>
          </div>



          <p className='mt-2'>
            For a mild acne breakout this is a great treatment in drying up spots.
          </p>

          <p className='mt-2 font-bold '>
            Iontophoresis:        </p>




          <p className='mt-2 '>
            A small electric current applied to the skin enabling the penetration of charged molecules deep into the skin. ​It is ideal for ageing, uneven

            or  dull, dehydrated skin tones.  The age-fighting ion-active serums, (highly potent concentrations of retinoid, hyaluronic acid or niacinamide),  are applied to the skin with a conductive gel (which cools and deeply hydrates the skin), reducing the signs of ageing, leaving the skin's texture supple and more uniform.
          </p>


        </div>
      </FadeUp>

    </div>
  );
}

export default SkinCare;
