import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'react-headless-accordion';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';

const CustomAccordion = ({ title, text }) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const accordionTransitionDuration = 800; // Set the same duration as the accordion transition

    return (
        <div className=''>
            <div className='max-w-[600px]  border-transparent bg-transparent mx-auto '>
                <div>
                    <Accordion
                        className='hover:opacity-95 card bg-gradient-to-l from-mainBtn to-secondaryBtn text-textBtn border-4 border-borderBtn font-semibold m-4 shadow-xl rounded-3xl'
                        transition={{ duration: `${accordionTransitionDuration}ms`, timingFunction: 'cubic-bezier(0, 0, 0.2, 1)' }}
                    >
                        <AccordionItem>
                            <AccordionHeader
                                className='items-center flex justify-between w-full  py-2 text-lg sm:text-2xl'
                                onClick={toggleAccordion}
                            >
                                <div className='flex p-4 items-center justify-between'>
                                    <h3 className={`accordion-title`}>{title}</h3>

                                </div>
                                <div className='flex p-4 items-center justify-between text-xl sm:text-2xl'>
                                <IoIosArrowForward
                                    className={isAccordionOpen ? 'rotate-90' : ''}
                                    style={{ transition: `transform 0.4s ease-in-out` }}
                                />
                                </div>
                             
                            </AccordionHeader>

                            <AccordionBody className='card-normal text-accordionText'>
                                <div className='accordion-body'>
                                    <div className='cardl mx-auto text-left text-sm sm:text-base '>
                                        <div className=' p-4'>
                                            <p className='font-medium my-1'>{text}</p>
                                            {/* Add more text content as needed */}
                                        </div>
                                    </div>
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default CustomAccordion;
