

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/swiper-bundle.css";
import Home01 from '../images/home01.jpg';
import "../css/HomeImageSwiper.css";
import HomeBanner from '../images/home-banner.jpg';
import Home02 from '../images/Home02.jpg';
import Home03 from '../images/Home03.jpeg';









SwiperCore.use([Navigation, Pagination, Autoplay]);

const HomeImageSwiper = () => {
    const customPrevButton = (
        <IoIosArrowBack size={30} color="#333" className="swiper-button-prev" />
    );

    const customNextButton = (
        <IoIosArrowForward size={30} color="#333" className="swiper-button-next" />
    );

    return (
        <Swiper
            navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            }}
            pagination={{ clickable: true }}
            loop
            speed={1000}
            autoplay={{ delay: 1000 }} // Set autoplay delay to 1 second
            className="swiper3 p-8 my-6"
        >
            <SwiperSlide>
                <img
                    className="w-[500px]"
                    src={HomeBanner}
                    alt=""
                />
            </SwiperSlide>
            <SwiperSlide>
                <img
                    className="w-[500px]"
                    src={Home03}
                    alt=""
                />
            </SwiperSlide>
            <SwiperSlide>
                <img
                    className="w-[500px]"
                    src={Home02}
                    alt=""
                />
            </SwiperSlide>

            <SwiperSlide>
                <img
                    className="w-[500px]"
                    src={Home01}
                    alt=""
                />
            </SwiperSlide>

            <div className="swiper-button-prev-custom">{customPrevButton}</div>
            <div className="swiper-button-next-custom">{customNextButton}</div>
        </Swiper>


    );
};

export default HomeImageSwiper;
