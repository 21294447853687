import React from 'react'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "../css/AboutTextSwiper.css";
// Add the missing import statements
// Import Swiper components and styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Import the base Swiper styles
import "swiper/css/navigation"; // Import Swiper navigation styles
import "swiper/css/pagination"; // Import Swiper pagination styles
import "swiper/css/autoplay"; // Import Swiper autoplay styles

// Import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";

SwiperCore.use([Navigation, Pagination, Autoplay]);
const AboutTextSwiper = () => {
    const customPrevButton = (
        <IoIosArrowBack size={30} color="#4f5186" className="swiper-button-prev" />
    );

    const customNextButton = (
        <IoIosArrowForward size={30} color="#4f5186" className="swiper-button-next" />
    );

    return (
        <Swiper
            navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            }}
            pagination={{ clickable: true }}
            loop
            speed={1000}
            autoplay={{ delay: 1000 }} // Set autoplay delay to 1 second
            className="swiper01 p-8 my-6"
        >
            <SwiperSlide>
                {/* <img src="https://images.pexels.com/photos/3785417/pexels-photo-3785417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="client_photo" style={{ width: '80px', height: '80px' }} className='rounded-full mx-auto' /> */}
                <p className='px-12 mt-6 text-xs lg:text-lg mx-auto font-medium max-w-[90%]  text-mainBtn' >
                    "wow wow wow - I've been coming for years - the results are amazing. Devora is truly professional not only in electrolysis and hair removal, but in all aspects of skin care and many health-related topics. Devorah is always kind and when you lie on that chair you feel like the only client she has - she gives her all! I feel truly privileged to be under such professional care and ever luckier to know someone as special as Devorah! Whoever is under Devora, should know she is the BEST!!"                         </p>
                {/* <p className='text-sm mx-auto rtl font-bold text-secondary mt-2' dir='rtl'>אורית מימון
                </p> */}


            </SwiperSlide>

            <SwiperSlide>
                {/* <img src="https://images.pexels.com/photos/3785417/pexels-photo-3785417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="client_photo" style={{ width: '80px', height: '80px' }} className='rounded-full mx-auto' /> */}
                <p className='px-12 mt-6 text-xs lg:text-lg mx-auto font-medium max-w-[90%]  text-mainBtn' >
                    "Devorah is not just a beautician, she's half a doctor too! Devorah understands if hair growth is unnatural and urged me to do a hormonal test- which she was right to! I could text her for advice about many hair-related topics like if to wax with varicose veins- and I fully trust in her judgment! All that is besides the professional hair removal and the good laughs I get from her treatments! Thanks, Devorah"                </p>
                {/* <p className='text-sm mx-auto rtl font-bold text-secondary mt-2' dir='rtl'>אורית מימון
                </p> */}


            </SwiperSlide>

            <SwiperSlide>
                {/* <img src="https://images.pexels.com/photos/3785417/pexels-photo-3785417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="client_photo" style={{ width: '80px', height: '80px' }} className='rounded-full mx-auto' /> */}
                <p className='px-12 mt-6 text-xs lg:text-lg mx-auto font-medium max-w-[90%]  text-mainBtn' >
                    Came on here to say this!
                    I was gonna post on her wall, but I don't find the option..
                    I had the BEST facial with Devorah! And I've been around..
                    Devorah is super professional and patient! I came out looking 10 years younger! It was like magic!
                    Thank you so much Devorah's Lane!
                </p>
                <p className='text-sm mx-auto rtl font-bold text-secondary mt-2'> Malky Rosenberg

                </p>


            </SwiperSlide>


            <div className="swiper-button-prev-custom">{customPrevButton}</div>
            <div className="swiper-button-next-custom">{customNextButton}</div>
        </Swiper>
    );
}

export default AboutTextSwiper