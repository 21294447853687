import React, { useEffect, useRef } from 'react'; // Import the 'useRef' hook
import { Link } from 'react-router-dom';
import { AiOutlineWhatsApp, AiOutlineCar } from 'react-icons/ai';
import Beforenafter1 from '../images/beforenafter-01.jpg';
import Beforenafter2 from '../images/beforenafter-02.jpg';
import Beforenafter3 from '../images/beforenafter-03.jpg';
import ScrollToTopOnRefresh from '../components/ScrollToTopOnRefresh'; // Corrected import
import FadeUp from '../components/FadeUp';
import AboutTextSwiper from '../components/AboutTextSwiper'
import HomeFixed from '../images/homeFixed.jpg';
import ImageScroll from '../components/ImageScroll';


function About() {

  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollY = window.scrollY;
        containerRef.current.style.backgroundPositionY = -scrollY * 2 + 'px';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className='p-6 imgBg'>
      <ScrollToTopOnRefresh />
      <FadeUp>
        <h1 className='text-center text-3xl sm:text-5xl my-4 font-semibold text-secondaryBtn'>ABOUT</h1>
        <div className='bg-[#ed9aa7] h-[2px] w-[70%] sm:w-[250px] mx-auto'></div>

        <div className='text-center text-secondaryBtn mb-12 mt-6 sm:text-2xl mx-auto max-w-[1200px] font-medium'>
          <p>
            Unwanted hair is the dream of most woman and teenagers. My specialty and expertise is permanent hair removal using electrolysis & laser (IPL) – the best available technologies for removing unwanted face and body hair – leaving you with smooth and silky skin.
          </p>

          <Link to={'/laser-hair-removal'} className="max-w-[260px] py-3 mt-4 text-base cursor-pointer mx-auto rounded-full flex border-4 bg-gradient-to-l from-mainBtn to-secondaryBtn text-textBtn px-7 border-borderBtn hover:opacity-[0.80] duration-300 shadow-lg">
            LASER HAIR REMOVAL
          </Link>
        </div>

        <div className='text-center text-secondaryBtn my-12 sm:text-2xl mx-auto max-w-[1200px] font-medium'>
          <p>
            My complementary treatments include Leo Hyper-Pulse technology, which reverses the effects of sun damage including pigmentation, as well as RF anti-ageing facials and light-based treatments, which leave you with a fresh, more youthful look.
          </p>

          <Link to={'/laser-skin-care'} className="max-w-[220px] py-3 mt-4 text-base cursor-pointer mx-auto rounded-full flex border-4 bg-gradient-to-l from-mainBtn to-secondaryBtn text-textBtn px-7 border-borderBtn hover:opacity-[0.80] duration-300 shadow-lg">
            LASER SKIN CARE
          </Link>
        </div>

        <div className='text-center text-secondaryBtn my-12 sm:text-2xl mx-auto max-w-[1200px] font-medium'>
          <p>
            I’m an approved seller of award-winning DERMALOGICA skin care products, which provide continuous professional-grade care at home, so that the smoothness, brightness and healthy glow of your skin stays with you long after you leave my salon.
          </p>

          <Link className="max-w-[160px] py-3 mt-4 text-base cursor-pointer mx-auto rounded-full flex border-4 bg-gradient-to-l from-mainBtn to-secondaryBtn text-textBtn px-7 border-borderBtn hover:opacity-[0.80] duration-300 shadow-lg" to={'/skin-care'}>
            SKIN CARE
          </Link>
        </div>

        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>






        <div className='text-left text-secondaryBtn my-6 sm:text-2xl mx-auto max-w-[1200px] font-medium'>
          <p>
            I’m passionate about sharing my knowledge and expertise to help bring out the unique beauty in every woman.
          </p>
          <p className='mt-2'>
            Conveniently located in Ramat Bet Shemesh A, close to public transport and ample parking.
          </p>

          <div className='flex mt-2 h-[50px]'>
            <a href='https://www.waze.com/he/live-map/directions/il/%D7%9E%D7%97%D7%95%D7%96-%D7%99%D7%A8%D7%95%D7%A9%D7%9C%D7%99%D7%9D/%D7%91%D7%99%D7%AA-%D7%A9%D7%9E%D7%A9/%D7%90%D7%A9%D7%A8-23?to=place.EiBBc2hlciBTdCAyMywgQmV0IFNoZW1lc2gsIElzcmFlbCIwEi4KFAoSCc3B5m1jxAIVEfke5wUQcbi0EBcqFAoSCWkG2nNjxAIVETQg3pAJU9Ka' target="_blank" className='flex items-center'>
              <div className="group flex items-center justify-center w-11 h-11 rounded-full border-[1px] border-ring bg-gradient-to-l from-mainRing to-secondaryRing hover:scale-[110%] duration-200">
                <div className="group-hover:scale-[110%]">
                  <AiOutlineCar size={25} className='text-icons' />
                </div>
              </div>
              <p className='mt-1 font-bold text-main px-2'>Click here to navigate</p>
            </a>
          </div>

          <div className='flex mt-2 h-[50px]'>
            <a href='https://wa.me/+972584060644' target="_blank" className='flex items-center'>
              <div className="group flex items-center justify-center w-11 h-11 rounded-full border-[1px] border-ring bg-gradient-to-l from-mainRing to-secondaryRing hover:scale-[110%] duration-200">
                <div className="group-hover:scale-[110%]">
                  <AiOutlineWhatsApp size={25} className='text-icons' />
                </div>
              </div>
              <p className='mt-1 font-bold text-main px-2'>Contact me for a free initial consultation</p>
            </a>
          </div>
        </div>

        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>

        <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn'>TESTIMONIALS</h1>

        <AboutTextSwiper />
        <div className='mt-10 flex flex-col sm:flex-row justify-around mx-auto max-w-[1200px] '>
          <img className='w-[90%] sm:w-[40%] mx-auto rounded-xl' src={Beforenafter1} alt="" />
          <img className='w-[90%] sm:w-[40%] mx-auto mt-10 sm:mt-0 rounded-xl' src={Beforenafter2} alt="" />
        </div>

        <div className='max-w-[1200px] flex justify-around mt-10 mx-auto '>
          <img className='w-[90%] sm:w-[40%] mx-auto rounded-xl' src={Beforenafter3} alt="" />
        </div>
      </FadeUp>

    </div>
  );

}

export default About;
