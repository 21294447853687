// TypewriterComponent.js
import React, { useEffect, useRef } from 'react';
import Typewriter from 'typewriter-effect/dist/core';

function TypewriterComponent({ text }) {
  const typewriterRef = useRef(null);

  useEffect(() => {
    if (typewriterRef.current) {
      const typewriter = new Typewriter(typewriterRef.current, {
        loop: false,
        delay: 20,
        autoStart: true, // Ensure the animation starts automatically
        wrapperClassName: '', // Remove any wrapper class if needed
      });

      typewriter
        .typeString(text)
        .start();
    }
  }, [text]);

  return <div ref={typewriterRef}></div>;
}

export default TypewriterComponent;
