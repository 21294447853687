import React from 'react';
import HyperPulse from '../images/hyperPulse.png';
import ReactPlayer from 'react-player';
import Laser01 from '../images/laser01.png';
import Laser02 from '../images/laser02.png';
import ScrollToTopOnRefresh from '../components/ScrollToTopOnRefresh'; // Corrected import
import CustomAccordion from '../components/CustomAccordion';
import FadeUp from '../components/FadeUp';
import LaserHairRemovalTextSwiper from '../components/LaserHairRemovalTextSwiper';
import LaserHairRemovalImageSwiper from '../components/LaserHairRemovalImageSwiper';
import TypewriterComponent from '../components/TypewriterComponent'




function LaserHairRemoval() {


  return (
    <div className='p-6 imgBg'>
      <ScrollToTopOnRefresh />
      <FadeUp>
        <h1 className='text-center text-3xl sm:text-5xl my-4 font-semibold text-secondaryBtn'>LASER HAIR REMOVAL</h1>
        <div className='bg-[#ed9aa7] h-[2px] w-[70%] sm:w-[600px] mx-auto'></div>

        <div className='text-center text-secondaryBtn  mb-12 mt-6 sm:text-2xl mx-auto max-w-[1200px] font-medium'>
          <p>
            LASER HAIR REMOVAL
            HYPER PULSE
            A.L.T TECHNOLOGY
          </p>

          {/* <img src={HyperPulse} className='mx-auto my-4' alt="laser-machine" /> */}
          <LaserHairRemovalImageSwiper />

        </div>
        <div className='mx-auto max-w-[1200px]'>
          <div className='text-center text-white mx-auto my-6   max-w-md sm:text-2xl  font-medium'>
            <p className='bg-[#ed9aa7] rounded-full  py-4 mx-auto '>
              PAIN FREE
            </p>
            <p className='mt-4 bg-[#ed9aa7] rounded-full p-4 mx-auto '>
              FAST
            </p>
            <p className='mt-4 bg-[#ed9aa7] rounded-full  p-4 mx-auto '>
              SUITABLE ON TEENAGERS
            </p>
            <p className='mt-4 bg-[#ed9aa7] rounded-full   p-4 mx-auto '>
              LONG LASTING RESULTS
            </p>
          </div>
        </div>

        {/* <div className='mx-auto max-w-[1200px]'>
          <div className='text-left text-secondaryBtn mx-auto my-6  sm:text-2xl  font-medium'>
            <li>
              PAIN FREE
            </li>
            <li className='mt-2'>
              FAST
            </li>
            <li className='mt-2'>
              SUITABLE ON TEENAGERS
            </li>
            <li className='mt-2'>
              LONG LASTING RESULTS
            </li>
          </div>
        </div> */}

        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>

        <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn max-w-[1200px] mx-auto'>
          Leo Hyper Pulse
          A REVOLUTION IN THE FIELD OF HAIR REMOVAL
        </h1>

        <div className='text-left text-secondaryBtn  my-6  sm:text-2xl mx-auto  max-w-[1200px] font-medium'>
          <p>
            With laser hair removal becoming much more affordable, shaving, waxing and epilators will soon be a thing of the past, allowing you to experience smooth skin.
          </p>
          <p className='mt-2'>
            Laser works by focusing a beam at the base of each hair follicle to stop its growth.
          </p>
          <p className='mt-2'>
            Unlike most other laser technologies, which are time-consuming and uncomfortable, I use A.L.T Hyper Pulse technology, which employs high-energy pulses, in a single sliding movement (One Glide®). Together with its patented skin cooling system, it leaves you feeling soothed, cool, comfortable and pain-free. It is safe (cannot change DNA), super-fast and suitable for any part of the body, including the most sensitive and problem areas like your face, bikini area, legs, chest and underarms.
          </p>
          <p className='mt-2 font-bold'>
            <TypewriterComponent text="            Most clients do not require post-treatment care. I highly recommend applying SPF 50 sunscreen on the treated areas during the summer.
"/>
          </p>
        </div>

        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>

        <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn max-w-[1200px] mx-auto'>
          In a nutshell, A.L.T Hyper Pulse hair removal technology…
        </h1>

        <div className='text-left text-secondaryBtn  my-6  sm:text-2xl mx-auto  max-w-[1200px] font-medium'>
          <li className="list-item">
            Works with Hyperpulse spray as opposed to unpleasant gels, providing the added advantage of being therapeutic to your skin.
          </li>
          <li className='mt-2 list-item'>
            Is more effective than most other hair removal products.
          </li>
          <li className='mt-2 list-item'>
            Removes hair at unparalleled speed – even lighter and finer hairs.
          </li>
          <li className='mt-2 list-item'>
            Uses a high-performance cooling system that ensures the treatment area remains optimally cool.
          </li>
          <li className='mt-2 list-item'>
            Leaves you with exceptionally smooth skin.
          </li>
          <li className='mt-2 list-item'>
            Is painless with no side effects.
          </li>
          <li className='mt-2 list-item'>
            Is suitable for all skin types.
          </li>
          <li className='mt-2 list-item'>
            Allows treatment throughout the year.
          </li>
          <li className='mt-2 list-item font-bold'>
            Fast results for ages 16 and over.
          </li>
        </div>

        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>

        <div className='aspect-video my-4 mx-auto max-w-[1200px]'>
          <ReactPlayer
            url="https://youtu.be/8vcyhM4zfIs?si=Y21DnBDPmTTngGqV"
            controls={true}
            width='100%'
            height='100%'
            className='rounded-lg'
          />
        </div>
        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>

        <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn'>CLIENTS</h1>
        <LaserHairRemovalTextSwiper />
        {/* <div className='text-left text-secondaryBtn  my-6  sm:text-2xl mx-auto  max-w-[600px] font-medium'>
          <p className='border-2 p-4 border-[#ed9aa7] rounded-xl text-xs sm:text-base'>
            "Devorah I could always rely on you-"Devorah knows best" My hairline etc are so amazing. I can stare at the mirror looking at the perfection and markless hairline - no sores or bleeding eva!
            Thank yo"
          </p>
          <p className='mt-4 border-2 p-4 border-[#ed9aa7] rounded-xl text-xs sm:text-base'>
            "orah has provided a fantastic service!
            My laser treatments on my forehead and
            Side of my face are becoming more & more
            Spread out – my last being 3 months ago.
            WELL WORTH IT!
            Thanks for accommodating me whenever
            I needed J"
          </p>
          <p className='mt-4 border-2 p-4 border-[#ed9aa7] rounded-xl text-xs sm:text-base'>
            "Devorah is the one who knows best how to deal with hairlines (and of course facial hair) with so much care (and patience when you just want to go higher and higher.......!)
            I would recommend her to anyone!
          </p>
        </div> */}

        <div className='my-10 flex flex-col sm:flex-row justify-around mx-auto max-w-[1200px] '>
          <img className='w-[90%] sm:w-[40%] mx-auto rounded-xl' src={Laser01} alt="" />
          <img className='w-[90%] sm:w-[40%] mx-auto mt-10 sm:mt-0 rounded-xl' src={Laser02} alt="" />
        </div>

        <div className='bg-[#ed9aa7] h-[2px] max-w-[1200px] mx-auto'></div>
        <h1 className='text-center text-3xl sm:text-5xl my-4 mt-10 font-semibold text-secondaryBtn'>WHAT DO PEOPLE ASK?</h1>
        <div className='text-center max-w-[700px]  text-secondaryBtn  my-6  sm:text-2xl mx-auto  font-medium'>
          <CustomAccordion title="What does it feel like?" text="Hyper pulse has a built-in cooling system, it protects the surrounding skin tissues, and numbs the skin. It feels cold to the skin with a mild flick." />
          <CustomAccordion title="Is it permanent?" text="Hyper Pulse Hair Removal provides up to 90% hair reduction. Maintained with top-up sessions every 6-12 months." />
          <CustomAccordion title="Will it work on my skin tone?" text="Hyperpulse technology allows for the safe treatment of all skin tones." />
        </div>
      </FadeUp>
    </div>
  );


}

export default LaserHairRemoval;
