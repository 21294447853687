import React from 'react'
import { AiOutlineWhatsApp } from 'react-icons/ai'


const IdanWeb = () => {
    return (
        <div>
            <section className="py-2 bg-gray-200 px-2  ">
                <div className=" flex-col   rounded-3xl  mx-auto flex items-center   ">
                    <a href='https://wa.link/frxv3i' target='blank' className="max-w-[240px]  font-extrabold leading-tight text-center text-gray-90 text-black customFont" dir='rtl'>אתר זה נבנה על ידי IDAN WEB</a>
                    <a href='https://wa.link/frxv3i' target='blank' className='flex flex-col justify-center items-center hover:scale-125 duration-300 '>
                        {/* <img src={SonStudioLogo} alt="logoIcon" style={{ width: '', height: '' }} /> */}
                    </a>

                    {/* <a href='https://wa.me/+972538250965' classNames="px-8 py-3 text- font-bold rounded-full border-white border-2  text-white hover:scale-[110%] hover:bg-gray-800 hover:border-white hover:border-2  hover:bg-violet-500 duration-200 font-medium">DIGICARD</a> */}
                    <a href='https://wa.link/frxv3i' target='blank' className=' flex mx-auto w-[160px]  items-center justify-around bg-black  py-2 rounded-full hover:opacity-90'>
                        <a href='https://wa.link/frxv3i' target='blank' className='flex  items-center'>
                            <div className="group flex items-center justify-center w-7 h-7 rounded-full bg-green-500 hover:scale-[110%] duration-200">
                                <div className="group-hover:scale-[110%]">
                                    <AiOutlineWhatsApp size={20} color='white' />
                                </div>
                            </div>
                        </a>
                        <h1 className="font-semibold leading-tight text-center text-gray-50 customFont" dir='rtl'>שלחו לי הודעה</h1>
                    </a>

                </div>
            </section>
        </div>
    )
}

export default IdanWeb