import React from 'react'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "../css/ElectrolysisTextSwiper.css";
// Add the missing import statements
// Import Swiper components and styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Import the base Swiper styles
import "swiper/css/navigation"; // Import Swiper navigation styles
import "swiper/css/pagination"; // Import Swiper pagination styles
import "swiper/css/autoplay"; // Import Swiper autoplay styles

// Import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";

SwiperCore.use([Navigation, Pagination, Autoplay]);
const LaserHairRemovalTextSwiper = () => {
    const customPrevButton = (
        <IoIosArrowBack size={30} color="#4f5186" className="swiper-button-prev" />
    );

    const customNextButton = (
        <IoIosArrowForward size={30} color="#4f5186" className="swiper-button-next" />
    );

    return (
        <Swiper
            navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            }}
            pagination={{ clickable: true }}
            loop
            speed={1000}
            autoplay={{ delay: 1000 }} // Set autoplay delay to 1 second
            className="swiper2 p-8 my-4"
        >



            <SwiperSlide>
                {/* <img src="https://images.pexels.com/photos/3785417/pexels-photo-3785417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="client_photo" style={{ width: '80px', height: '80px' }} className='rounded-full mx-auto' /> */}
                <p className='px-12 mt-6 text-xs sm:text-lg mx-auto font-medium max-w-[90%]  text-mainBtn' >
                    "Devorah I could always rely on you-"Devorah knows best" My hairline etc are so amazing. I can stare at the mirror looking at the perfection and markless hairline - no sores or bleeding eva! Thank you"                </p>
                {/* <p className='text-sm mx-auto rtl font-bold text-secondary mt-2' dir='rtl'>אורית מימון
                </p> */}


            </SwiperSlide>
            <SwiperSlide>
                {/* <img src="https://images.pexels.com/photos/3785417/pexels-photo-3785417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="client_photo" style={{ width: '80px', height: '80px' }} className='rounded-full mx-auto' /> */}
                <p className='px-12 mt-6 text-xs sm:text-lg mx-auto font-medium max-w-[90%]  text-mainBtn' >
                    "Devorah has provided a fantastic service! My laser treatments on my forehead and Side of my face are becoming more & more Spread out – my last being 3 months ago. WELL WORTH IT! Thanks for accommodating me whenever I needed"                </p>
                {/* <p className='text-sm mx-auto rtl font-bold text-secondary mt-2' dir='rtl'>אורית מימון
                </p> */}


            </SwiperSlide>
            <SwiperSlide>
                {/* <img src="https://images.pexels.com/photos/3785417/pexels-photo-3785417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="client_photo" style={{ width: '80px', height: '80px' }} className='rounded-full mx-auto' /> */}
                <p className='px-12 mt-6 text-xs sm:text-lg mx-auto font-medium max-w-[90%]  text-mainBtn' >
                    "Devorah is the one who knows best how to deal with hairlines (and of course facial hair) with so much care (and patience when you just want to go higher and higher.......!) I would recommend her to anyone!                </p>
                {/* <p className='text-sm mx-auto rtl font-bold text-secondary mt-2' dir='rtl'>אורית מימון
                </p> */}
            </SwiperSlide>


            <div className="swiper-button-prev-custom">{customPrevButton}</div>
            <div className="swiper-button-next-custom">{customNextButton}</div>
        </Swiper>
    );
}

export default LaserHairRemovalTextSwiper