import React from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom'; // Import from 'react-router' instead of 'react-router-dom'
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Electrolysis from './pages/Electrolysis';
import LaserHairRemoval from './pages/LaserHairRemoval';
import LaserSkinCare from './pages/LaserSkinCare';
import SkinCare from './pages/SkinCare';
import Footer from './components/Footer';
import IdanWeb from './components/IdanWeb';
import ScrollToTopOnRefresh from './components/ScrollToTopOnRefresh'; // Corrected import
import BackToTopButton from './components/BackToTopButton'; // Import the BackToTopButton component

function App() {

  return (
    <Router>
      <Navbar />
      <ScrollToTopOnRefresh />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/electrolysis" element={<Electrolysis />} />
        <Route path="/laser-hair-removal" element={<LaserHairRemoval />} />
        <Route path="/laser-skin-care" element={<LaserSkinCare />} />
        <Route path="/skin-care" element={<SkinCare />} />
      </Routes>
      <Footer />
      <IdanWeb />
      <BackToTopButton />
    </Router>
  );
}

export default App;
